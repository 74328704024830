// app global css in SCSS form
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patua+One&display=swap');

::-webkit-scrollbar {
  height: 12px;
  width: 14px;
  background: transparent;
  z-index: 12;
  overflow: visible;
}

::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: silver;
  border-radius: 10px;
  z-index: 12;
  border: 4px solid rgba(0,0,0,0);
  background-clip: padding-box;
  -webkit-transition: background-color .28s ease-in-out;
  transition: background-color .28s ease-in-out;
  margin: 4px;
  min-height: 32px;
  min-width: 32px;
}

body {
  font-family: 'Roboto Slab', serif;
}

.font-display {
  font-family: 'Patua One', serif;
}